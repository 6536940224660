import * as React from "react"
import { Helmet } from "react-helmet"
import Logo from "../images/matter-ux.png"

// styles
const pageStyles = {
  color: "#232129",
  backgroundColor: "#fff",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  boxSizing: "border-box",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
}
const headingAccentStyles = {
  color: "#232129",
}
const paragraphStyles = {
  marginBottom: 48,
}
const logoStyles = {
  width: "3rem",
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Matter UX - Solving Customer Problems through Experience Design</title>
      </Helmet>
      <h1 style={headingStyles}>
        <img src={Logo} alt="Matter UX logo" style={logoStyles} />
        <br />
        Matter UX
        <br />
        <span style={headingAccentStyles}>Do more than solve visual problems<br/>Solve customer problems</span>
        <span role="img" aria-label="Fire emoji">
          🔥
        </span>
      </h1>
      <p style={paragraphStyles}>
        Get in touch <span role="img" aria-label="Pointer finger emoji">👉</span> caleb@matterux.com
      </p>

    </main>
  )
}

export default IndexPage
